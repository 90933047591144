import { GatsbyImage, StaticImage } from 'gatsby-plugin-image';
import { Panels } from '../components/Panels';
import { graphql, Link } from 'gatsby';
import { Hero } from '../components/Hero';
import { Seo } from '../components/Seo';
import { Fragment } from 'react';

const OurServices: Page = ({ data: { items } }) => {
    return (
        <Fragment>
            <Seo
                title="Our Services | Clermont Kids Dentistry & Orthodontics, P.A."
                description={`Here at Clermont Kids Dentistry & Orthodontics we strive to meet all your pediatric dental needs, from your little infant to your young adult. Please to hesitate to call or ask us about any of our dental services.`}
            />
            <main>
                <Hero type="service" title="Our Services">
                    <StaticImage
                        alt="girl with thumbs up"
                        className="hero-image"
                        src="../images/services-1.jpg"
                    />
                </Hero>
                <Panels>
                    {items.edges.map(service => {
                        return (
                            <Link
                                key={service.node.frontmatter.title}
                                to={service.node.frontmatter.slug}
                            >
                                <GatsbyImage
                                    alt={service.node.frontmatter.thumbnail.name}
                                    image={
                                        service.node.frontmatter.thumbnail.childImageSharp
                                            .gatsbyImageData
                                    }
                                />
                                <p>{service.node.frontmatter.title}</p>
                            </Link>
                        );
                    })}
                </Panels>
            </main>
        </Fragment>
    );
};

export const query = graphql`
    query {
        items: allMdx(
            sort: { order: ASC, fields: frontmatter___order }
            filter: { frontmatter: { list: { eq: true }, type: { eq: "service" } } }
        ) {
            edges {
                node {
                    id
                    frontmatter {
                        type
                        images {
                            childImageSharp {
                                gatsbyImageData(quality: 100, placeholder: NONE)
                            }
                            name
                        }
                        title
                        slug
                        thumbnail {
                            childImageSharp {
                                gatsbyImageData(quality: 100, placeholder: NONE)
                            }
                            name
                        }
                    }
                }
            }
        }
    }
`;

export default OurServices;
